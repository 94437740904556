body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  scrollbar-width: thin;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-icons {
  vertical-align: middle;
}

.CodeMirror {
  height: 100%;
  z-index: 0;
}

.react-codemirror2 {
  height: 100%;
}

#conversejs {
  z-index: 2000;
}

.react-grid-item {
  z-index: 20;
}